@import 'custom';

@import "~bootswatch/dist/flatly/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/flatly/bootswatch";

@import "~@fortawesome/fontawesome-free/css/all.css";

@import "~slider-fotorama/dist/fotorama.css";

/* theme */
@import 'theme';

/* pentru conversații */
@import 'components/chat';
