:root {
  --body-bg: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  --msger-bg: #fff;
  --border: 2px solid #ddd;
  --left-msg-bg: #ececec;
  --right-msg-bg: #499341;
}

.remove-image-form {
  margin-top: 15px;
}

.tag.price {
  line-height: 1;

  strong {
    color: #000000;
    font-weight: 600;
  }
}

.my-properties {
  .heading {
    display: inline-block;
  }
}

tr.incative-state {
  opacity: 0.7;
}

.form-check {
  .form-check {
    padding-right: 15px;
  }
}

.image-property-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: inline-block;
  min-height: 60px;
  text-align: center;
}


.thumnail_item {
  margin-bottom: 20px;

  .img-fluid {
    margin-bottom: 10px;
    border: 1px solid #ddd;
    padding: 3px;

    img {
      max-width: 100%;
    }
  }

  .btn-icon-custom {
    border: none;
    padding: 3px 10px 2px 10px;
    background: #dc3545;
    font-size: 12px;
    position: absolute;
    top: -10px;
    right: 15px;
    text-transform: none;
  }
}


.live__scroll {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 30px;
}

.scroll_listing_item {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 15px;
}

.scroll_item {
  position: relative;
  width: 220px;
  flex: 0 0 220px;
  padding: 0 15px;
}

.scroll_item img {
  border: 1px solid red;
}

.scroll_item .btn {
  position: absolute;
  top: 15px;
  right: 31px;
  background-color: red;
  color: white;
  font-size: 12px;
  padding: 8px 12px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.scroll_item .btn:hover {
  background-color: black;
}

.image-profile-container {
  .icon-photo-hover {
    opacity: 0;
    animation: fadeOut .2s both ease;
    background-image: url('../../img/svg/photo.svg');
    background-position: center;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: -23px;
    left: 50%;
    margin-left: -20px;
  }

  &:hover {
    .icon-photo-hover {
      display: inline-block;
      opacity: 1;
      animation: fadeIn .3s both ease;
    }
  }
}

.my-properties table tbody tr td .actions .edit {
  float: inherit;
}

.my-properties .table tr:last-child td {
  border: none;
}

.current-avatar {
  border-top: 1px solid #f6f6f6;
  padding-top: 15px;
  margin-top: 15px;

  img {
    display: inline-block;
    padding: 5px;
    border: 1px solid #f6f6f6;
    border-radius: 5px;
  }
}

.my-messages {
  background: #FFFFFF;
  box-shadow: 0 0 20px rgba(38, 38, 38, 0.2);
  padding-top: 30px;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down(sm) {
    padding-top: 15px;
    margin-top: 15px;
  }

  .heading {
    padding-left: 30px;
    display: inline-block;

    @include media-breakpoint-down(sm) {
      padding-left: 15px;
      margin-bottom: 15px;
    }
  }

  .top-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .property-title {
    font-size: 18px;
    font-weight: 500;
    color: #202020;
    padding-bottom: 8px;
  }

  .inner-box {
    width: 100%;
    margin-bottom: 0;

    .property-features {
      columns: 2;
    }
  }
}

.conversation-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  .conversation-item {
    &:last-child {
      border-bottom: none;
    }
  }

  .paginate-loader {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 15px;

    a.loadMoreMsg {
      width: 100%;
      text-align: center;
      color: #727272;
      background: #f8f8f8;
      padding: 7px;
    }
  }
}

.conversation-inf {
  display: flex;
  flex-direction: row;
  flex: 1;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.conversation-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  //margin-bottom: 15px;
  padding: 15px 30px;
  border-bottom: 1px solid #fbfbfb;
  border-top: 1px solid #ededed;
  position: relative;
  background: #fcfcfe;

  @include media-breakpoint-down(md) {
    padding-left: 15px;
    padding-right: 15px;
  }

  &.is-owner {
    background: #f8f8f8;
  }

  .general-info {
    display: flex;
    flex-direction: row;
    align-items: center;

    .property-id {
      min-width: 110px;
      line-height: 1;
    }

    .property-price {
      min-width: 110px;
      padding: 0 5px;
      align-self: flex-start;
      line-height: 1.2;
      font-weight: 700;
      color: #499341;
    }
  }

  &:hover {
    background: #f6f6f6;
  }

  .property-info {
    line-height: 1.5;
  }

  .conversation-user {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 5px;
    padding-left: 5px;
    line-height: 1.2;
    width: 100%;
    max-width: 140px;

    @include media-breakpoint-down(sm) {
      max-width: 100%;
      margin-left: 0;
      padding-left: 0;
    }

    .customer-name {
      overflow: hidden;
      text-overflow: ellipsis;
      color: #499341;
      line-height: 1.2;
      margin: 0;
      padding-bottom: 5px;
    }

    .field-msg-time {
      span {
        font-size: 12px;
      }
    }
  }

  .conversation-property {
    flex: 1;
    padding-left: 21px;
    border-left: 1px solid #95c514;
    margin-left: 15px;

    .last-message {
      color: #242424;
      line-height: 1.2;
      margin: 0;
      padding-bottom: 5px;
    }

    .property-name {
      font-weight: bold;
      color: #000;
      font-size: 16px;
      flex: 1;
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      margin-left: 0;
      padding-left: 0;
      padding-top: 15px;
      border: none;
    }
  }

  &.seen-n .last-message {
    padding: 2px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      display: inline-block;
      width: 5px;
      height: 5px;
      background: red;
      border-radius: 50%;
      top: 7px;
      left: -10px;
    }
  }

  .property-info {
    color: #737373;
  }

  .customer-inf {
    @include media-breakpoint-down(md) {
      display: none;
    }

    img {
      max-width: 60px;
      max-height: 60px;
      background-clip: padding-box;
      border: 3px solid #fff;
      border-radius: 90%;
    }

    figcaption {
      display: inline-block;
      max-width: 60px;
      max-height: 60px;
      background-clip: padding-box;
      border: 3px solid #fff;
      border-radius: 90%;
    }
  }
}

/* Chat container */
.msger {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 867px;
  margin: 25px 10px;
  height: calc(100% - 50px);
  border: var(--border);
  border-radius: 5px;
  background: var(--msger-bg);
  box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
}

.msger-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: var(--border);
  background: #eee;
  color: #666;
}

.msger-chat {
  flex: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;

  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.msger-chat::-webkit-scrollbar {
  width: 6px;
}

.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}

.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}

.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
  padding: 10px 15px;

  &:hover {
    background: #f8f8f8;
    transition: ease-in-out .5s;
  }
}

.msg:last-of-type {
  margin: 0;
}

.msg-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}

.msg-bubble {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
  background: var(--left-msg-bg);
}

.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.msg-info-name {
  margin-right: 10px;
  font-weight: 600;
}

.msg-info-time {
  font-size: 0.85em;
}

.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
}

.right-msg {
  flex-direction: row-reverse;
}

.right-msg .msg-bubble {
  background: var(--right-msg-bg);
  color: #fff;
  border-bottom-right-radius: 0;
}

.right-msg .msg-img {
  margin: 0 0 0 10px;
}

.msger-inputarea {
  display: flex;
  padding: 10px;
  border-top: var(--border);
  background: #eee;
}

.msger-inputarea * {
  padding: 10px;
  border: none;
  border-radius: 3px;
  font-size: 1em;
}

.msger-input {
  flex: 1;
  background: #ddd;
}

.msger-send-btn {
  margin-left: 10px;
  background: #499341;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.23s;
}

.msger-send-btn:hover {
  background: #28a745;
}

.msger-chat {
  background-color: #fcfcfe;
  padding-bottom: 30px;
}

.msg-text {
  line-height: 1.3;
}

.back-btn-link {
  display: inline-block;
  margin-right: 30px;
}

.property-message-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 30px;
  padding-right: 30px;
  border-bottom: 1px solid #f6f6f6;
  padding-bottom: 15px;

  .details {
    width: 100%;
    order: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 5px;

    .details-listing {
      min-width: inherit;
      padding-top: 0;
      padding-left: 0;
      padding-right: 10px;
      line-height: 1.2;
      flex: 1;

      &:first-child {
        flex: 0;
      }

      p {
        font-size: 16px;
        color: #272727;
      }

      h5 {
        margin: 0;
        font-size: 16px;
        color: #888;
      }

      img {
        max-width: 80px;
        max-height: 45px;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}


.thumnail_item {

}